<template>
  <div class="container">
    <SecondNav></SecondNav>
    <div class="main1">
      <!--<div>
                <h1>农业知识库</h1>
            </div>-->
      <ul class="flex">
        <li v-for="(item,idx) in listTitle"
            :key="item.id"
            :class="index==idx?'active':'' "
            @click="son(item,idx)">{{item.columnName}}</li>
      </ul>
    </div>
    <div class="center">
      <ul>
        <li>
          <div>
            <ul class="flex">
              <li v-for="item in listTitle01"
                  :key="item.id"
                  @click="getListContent(item.id)">{{item.labelName}}</li>
            </ul>
          </div>
        </li>
        <!-- <li>
          <div>
            <ul class="flex">
              <li>引起猪胀气死亡的原因与防治</li>
              <li>畜禽弯曲菌病的症状与预防</li>
              <li>育肥猪的猪丹毒流行与防治</li>
            </ul>
          </div>
        </li> -->
      </ul>
    </div>
    <div class="bottom">
      <h1>{{contentTitle}}</h1>
      <div v-html="contentDetailed">
      </div>
    </div>
  </div>
</template>
<script>
import SecondNav from './secondNav'
import {
  getConsultList,
  getRuralFinanceList,
  getListContent
} from '../../../api/zhishiku'
export default {
  components: {
    SecondNav
  },
  data () {
    return {
      listTitle: [],
      listTitle01: [],
      listContent: [],
      contentTitle: '',
      contentDetailed: '',
      index: 0
    }
  },
  methods: {
    son (item, idx) {
      this.index = idx
      if (item.id !== undefined) {
        this.getlsit(item.id)
      }
    },
    getconsultList () {
      getConsultList({ parentId: '1386132357054349313' }).then((res) => {
        this.listTitle= res.data
        console.log(res)
        this.getlsit(res.data[0].id)
      })
    },
    getknowledgeBase () {
      getConsultList({ parentId: '1351722036304556034' }).then((res) => {
        this.listTitle = this.listTitle.concat(res.data)
      })
    },
    getlsit (columnId) {
      getRuralFinanceList({ columnId }).then((res) => {
        console.log(res)
        let newid =res.data[0].id
        this.getListContent(newid)
        this.listTitle01 = res.data
      })
    },
    getListContent (id) {
      getListContent({ labelId:id }).then((res) => {
         this.contentTitle=res.data.records[0].title
        // this.contentTitle = .title
        this.contentDetailed = res.data.records[0].content
        console.log()
      })
    }
  },
  mounted () {
    const id = '1385161265540165634'
    const id01 = '1385161265540165634'
    this.getconsultList()
    this.getknowledgeBase()
    this.getlsit(id)
    this.getListContent(id01)
  }
}
</script>
<style scoped>
@import '../../../assets/css/base.css';
.main2,
main {
  text-align: left;
}
.main1 {
  border-bottom: 1px solid #dcdcdc;
  margin-top: 20px;
}
.main1 {
  margin-top: 20px;
  text-align: left;
}
.main1 li {
  padding: 8px 18px;
  text-align: center;
  background-color: #f0f9eb;
  margin-right: 20px;
  cursor: pointer;
  border-radius: 4px;
  display: inline-block;
}
.main1 .active a {
  color: #fff;
}
.main1 a {
  color: #666;
}
.main1 .active {
  background-color: #93b067;
  color: #fff;
}
.main1 div {
  width: 80%;
  border-top: 4px solid #f00;
  margin: 40px auto 30px;
  position: relative;
}
.main1 div h1 {
  position: absolute;
  background-color: #ffffff;
  padding: 0 30px;
  font-size: 28px;
  color: #f00;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}
.main1 div h1::before {
  content: '';
  width: 16px;
  height: 16px;
  /* background: url(../assets/img/u345.png) no-repeat; */
  position: absolute;
  left: -8px;
  top: 50%;
  transform: translateY(-50%);
}
.main1 div h1::after {
  content: '';
  width: 16px;
  height: 16px;
  /* background: url(../assets/img/u345.png) no-repeat; */
  position: absolute;
  right: -8px;
  top: 50%;
  transform: translateY(-50%);
}
.center > ul {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  font-size: 14px;
  margin-top: 20px;
}
.center > ul > li {
  padding: 0 5px;
  margin-top: 20px;
}
.center > ul > li + li {
  border-top: 1px dashed #ccc;
}
.center > ul > li div:nth-child(1) {
  width: 150px;
  text-align: left;
  margin-top: 10px;
}
.center > ul > li div {
  line-height: 40px;
  flex-wrap: wrap;
}
.center > ul > li ul {
  flex-wrap: wrap;
  width: 1000px;
}
.center > ul > li li {
  margin-right: 40px;
}
.bottom {
  padding-top: 10px;
  text-align: left;
}
.bottom h1 {
  font-size: 20px;
  line-height: 60px;
  text-align: center;
}
.bottom p {
  margin: 10px 0;
}
header > img {
  position: relative;
  left: -50%;
  transform: translateX(50%);
}
</style>
