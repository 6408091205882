import { getRequest } from '@/api/axios'

// 获取栏目列表导航列表
export const getConsultList = (params) => {
  return getRequest('/blade-integrate/openapi/integratecolumninfo/column-list', params)
}
export const getRuralFinanceList = (params) => {
  return getRequest('/blade-integrate/openapi/integratelabelinfo/labelListByColumnId', params)
}
//
export const getListContent = (params) => {
  return getRequest('/blade-integrate/openapi/integrateknowledge/list', params)
}
